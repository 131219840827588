<template>
  <div>
    <b-form-group>
      <b-row>
        <b-col sm="16" lg="2">
          <b-form-group>
            <label for="routeIdFilter">Route ID:</label>
            <b-form-input
              id="routeIdFilter"
              v-model="routeIdFilter"
              placeholder="Enter Route ID"
              class="form-control font-small-2 w-auto mt-1"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col sm="16" lg="2">
          <b-form-group>
            <label for="driverFilter">Driver:</label>
            <b-form-input
              id="driverFilter"
              v-model="driverFilter"
              placeholder="Enter Driver"
              class="form-control font-small-2 w-auto mt-1"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col sm="16" lg="2">
          <b-form-group>
            <label for="dateFilter">Delivery Date:</label>
            <flat-pickr
              id="dateFilter"
              v-model="dateFilter"
              :config="{
                dateFormat: 'Y-m-d',
                defaultDate: 'today',
              }"
              class="form-control font-small-2 w-auto mt-1"
              placeholder="Choose Date"
              readonly="readonly"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
    <MyTable :rows="sortedOrders" :columns="columns" :is-loading="isLoading">
      <template v-slot="{ props }">
        <div v-if="props.column.field === 'route_id'">
          <b-button
            variant="primary"
            class="w-100"
            :to="`/order-list/${props.row.route_id}`"
          >
            {{ props.row.route_id }}
          </b-button>
        </div>

        <div v-else-if="props.column.field === 'order_id'">
          <b-badge
            v-for="(order, index) in props.row.orders"
            :key="index"
            variant="primary"
            :class="props.row.orders.length > 1 ? 'mb-1' : ''"
            class="w-100 d-block mx-auto badge-padding"
          >
            {{ order.id }}
          </b-badge>
        </div>

        <div v-else-if="props.column.field === 'actions'">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="25"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click="handleDelete(props.row)">
              <span class="text-danger">
                <feather-icon icon="TrashIcon" />
                Delete Route
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-else-if="props.column.field == 'delivery_date'">
          {{ $helpers.formatDate(props.row.delivery_date) }}
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
    </MyTable>

    <b-modal
      ref="modal"
      :title="modal.title"
      :size="modal.type.includes('route-summary') ? 'xl' : 'sm'"
      hide-footer
      no-close-on-backdrop
    >
      <RoutePlanSummary
        v-if="modal.type === 'route-summary'"
        :route-id="modal.data"
      />
    </b-modal>

    <!-- Assign Driver Modal -->
    <b-modal
      id="modal-3"
      ref="modal-3"
      centered
      ok-only
      ok-title="Save"
      hide-footer
      no-close-on-backdrop
    >
      <div>
        <div class="d-flex justify-content-center mt-2">
          <h2><b>Reassign Driver</b></h2>
        </div>
        <validation-observer ref="simpleRules">
          <validation-provider
            #default="{ errors }"
            name="Select Driver"
            rules="required"
          >
            <select
              v-model="form.driver_id"
              class="form-control form-select rounded"
              placeholder="Driver"
            >
              <option
                v-for="(driver, index) in driverList"
                :key="index"
                :value="driver.id"
              >
                {{ driver.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
            <div class="d-flex justify-content-center my-2">
              <b-button variant="gradient-primary" @click="assignDriver">
                SUBMIT
              </b-button>
            </div>
          </validation-provider>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MyTable from '@/views/components/MyTable.vue';
import RoutePlanSummary from '@/views/pages/inside/RoutePlanning/RoutePlanSummary.vue';
import { requestError } from '@/common/SwalOptions';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import OrderList from '@/views/pages/inside/RoutePlanning/OrderList.vue';

export default {
  name: 'RouteTracking',
  components: {
    MyTable,
    RoutePlanSummary,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    OrderList,
  },
  data() {
    return {
      dateFilter: '',
      routeIdFilter: '',
      driverFilter: '',
      form: { driver_id: '' },
      driverList: '',
      isLoading: false,
      rows: [],
      modal: {
        title: '',
        type: '',
        data: [],
      },
      sortedOrders: [
        {
          route_id: 1,
          orders: [{ order_id: 'Order1' }],
          // other fields...
        },
        // more orders...
      ],
      columns: [
        {
          label: 'Order ID',
          field: 'route_id',
          tdClass: 'align-middle',
        },
        {
          label: 'Recipient Name',
          field: 'driver.name',
          tdClass: 'align-middle',
        },
        {
          label: 'Recipient Address',
          field: 'no_orders',
          tdClass: 'align-middle',
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'align-middle',
        },
        {
          label: 'Actions',
          field: 'actions',
          tdClass: 'align-middle',
        },
      ],
    };
  },
  computed: {
    // sortedOrders() {
    //   return [...this.rows].reverse();
    // },
  },
  // created() {
  //   this.dateFilter = new Date().toISOString().split('T')[0];
  //   this.handlePreload();
  // },
  methods: {
    // async handlePreload() {
    //   this.isLoading = true;
    //   await this.getRoutes();
    //   this.isLoading = false;
    // },
    // async getRoutes() {
    //   const response = await this.$http.get(`routes/${this.dateFilter}`);
    //   if (response.data.status === false) {
    //     this.$swal(requestError);
    //     this.isLoading = false;
    //     return;
    //   }
    //   this.rows = response.data.routes;
    // },
    // handleDelete(data) {
    //   this.$swal({
    //     title: 'Are you sure?',
    //     text: `You are about to delete route plan - ${data.route_id}`,
    //     icon: 'warning',
    //     showCancelButton: true,
    //     reverseButtons: true,
    //     confirmButtonText: 'Yes, delete it!',
    //     customClass: {
    //       confirmButton: 'btn-danger',
    //       cancelButton: 'btn-secondary',
    //     },
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       await this.$http.get(`delete_route?route_id=${data.route_id}`);
    //       this.$swal({
    //         title: 'Route Deleted!',
    //         text: `You have deleted route plan ${data.route_id}`,
    //         icon: 'warning',
    //         customClass: {
    //           confirmButton: 'btn-warning',
    //         },
    //       });
    //       this.isLoading = true;
    //       await this.getRoutes();
    //       this.isLoading = false;
    //     }
    //   });
    // },
    handleModal(type, data = []) {
      const title = type.replaceAll('-', ' ').toLocaleUpperCase();
      this.modal = {
        title,
        type,
        data,
      };
      this.$refs.modal.show();
    },
    // async exportRoute(Route) {
    //   const response = await this.$http.get(`export_route/${Route}`, {
    //     responseType: 'blob',
    //   });
    //   const file = window.URL.createObjectURL(new Blob([response.data]));
    //   const anchor = document.createElement('a');
    //   anchor.href = file;
    //   anchor.setAttribute('download', `Route-${Route}-${Date.now()}.xlsx`);
    //   document.body.appendChild(anchor);
    //   anchor.click();
    // },
    // reassignDriver(Route) {
    //   this.form.route_id = Route;
    //   this.$http
    //     .get('get_driver_by_branch')
    //     .then((response) => (this.driverList = response.data.drivers));
    //   this.$refs['modal-3'].show();
    // },
    // assignDriver() {
    //   this.$refs.simpleRules.validate().then((success) => {
    //     if (success) {
    //       this.isLoading = true;
    //       this.$http
    //         .post('route/re_assign_route', this.form)
    //         .then((response) => {
    //           if (response.status == 200) {
    //             if (response.data.status == true) {
    //               this.$swal({
    //                 title: 'Driver Re-assigned !',
    //                 icon: 'success',
    //                 text: response.data.message,
    //                 confirmButtonText: 'OK',
    //                 customClass: {
    //                   confirmButton: 'btn btn-success',
    //                 },
    //                 buttonsStyling: false,
    //               });
    //             } else {
    //               this.$swal({
    //                 title: ' Error !',
    //                 icon: 'error',
    //                 text: response.data.message,
    //                 confirmButtonText: 'Confirm',
    //                 customClass: {
    //                   confirmButton: 'btn btn-warning',
    //                 },
    //                 buttonsStyling: false,
    //               });
    //             }
    //           }
    //           this.$refs['modal-3'].hide();
    //           this.handlePreload();
    //         });
    //       this.isLoading = false;
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
