var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',[_c('b-row',[_c('b-col',{attrs:{"sm":"16","lg":"2"}},[_c('b-form-group',[_c('label',{attrs:{"for":"routeIdFilter"}},[_vm._v("Route ID:")]),_c('b-form-input',{staticClass:"form-control font-small-2 w-auto mt-1",attrs:{"id":"routeIdFilter","placeholder":"Enter Route ID"},model:{value:(_vm.routeIdFilter),callback:function ($$v) {_vm.routeIdFilter=$$v},expression:"routeIdFilter"}})],1)],1),_c('b-col',{attrs:{"sm":"16","lg":"2"}},[_c('b-form-group',[_c('label',{attrs:{"for":"driverFilter"}},[_vm._v("Driver:")]),_c('b-form-input',{staticClass:"form-control font-small-2 w-auto mt-1",attrs:{"id":"driverFilter","placeholder":"Enter Driver"},model:{value:(_vm.driverFilter),callback:function ($$v) {_vm.driverFilter=$$v},expression:"driverFilter"}})],1)],1),_c('b-col',{attrs:{"sm":"16","lg":"2"}},[_c('b-form-group',[_c('label',{attrs:{"for":"dateFilter"}},[_vm._v("Delivery Date:")]),_c('flat-pickr',{staticClass:"form-control font-small-2 w-auto mt-1",attrs:{"id":"dateFilter","config":{
              dateFormat: 'Y-m-d',
              defaultDate: 'today',
            },"placeholder":"Choose Date","readonly":"readonly"},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}})],1)],1)],1)],1),_c('MyTable',{attrs:{"rows":_vm.sortedOrders,"columns":_vm.columns,"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var props = ref.props;
return [(props.column.field === 'route_id')?_c('div',[_c('b-button',{staticClass:"w-100",attrs:{"variant":"primary","to":("/order-list/" + (props.row.route_id))}},[_vm._v(" "+_vm._s(props.row.route_id)+" ")])],1):(props.column.field === 'order_id')?_c('div',_vm._l((props.row.orders),function(order,index){return _c('b-badge',{key:index,staticClass:"w-100 d-block mx-auto badge-padding",class:props.row.orders.length > 1 ? 'mb-1' : '',attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(order.id)+" ")])}),1):(props.column.field === 'actions')?_c('div',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"25"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleDelete(props.row)}}},[_c('span',{staticClass:"text-danger"},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_vm._v(" Delete Route ")],1)])],1)],1):(props.column.field == 'delivery_date')?_c('div',[_vm._v(" "+_vm._s(_vm.$helpers.formatDate(props.row.delivery_date))+" ")]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('b-modal',{ref:"modal",attrs:{"title":_vm.modal.title,"size":_vm.modal.type.includes('route-summary') ? 'xl' : 'sm',"hide-footer":"","no-close-on-backdrop":""}},[(_vm.modal.type === 'route-summary')?_c('RoutePlanSummary',{attrs:{"route-id":_vm.modal.data}}):_vm._e()],1),_c('b-modal',{ref:"modal-3",attrs:{"id":"modal-3","centered":"","ok-only":"","ok-title":"Save","hide-footer":"","no-close-on-backdrop":""}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('h2',[_c('b',[_vm._v("Reassign Driver")])])]),_c('validation-observer',{ref:"simpleRules"},[_c('validation-provider',{attrs:{"name":"Select Driver","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.driver_id),expression:"form.driver_id"}],staticClass:"form-control form-select rounded",attrs:{"placeholder":"Driver"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "driver_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.driverList),function(driver,index){return _c('option',{key:index,domProps:{"value":driver.id}},[_vm._v(" "+_vm._s(driver.name)+" ")])}),0),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('div',{staticClass:"d-flex justify-content-center my-2"},[_c('b-button',{attrs:{"variant":"gradient-primary"},on:{"click":_vm.assignDriver}},[_vm._v(" SUBMIT ")])],1)]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }