<template>
  <b-overlay :show="isLoading">
    <AppCollapse type="margin">
      <AppCollapseItem v-for="(item, index) in summary" :key="index" title="">
        <template #header>
          <b-badge variant="primary" class="badge-padding">
            Order {{ item.id }}
          </b-badge>
        </template>

        <b-row>
          <b-col cols="12" lg="6" class="px-4 mb-2">
            <div class="mb-2">
              <h3>
                <FeatherIcon icon="TruckIcon" size="30" />
                <span class="ml-2">Order Tracking</span>
              </h3>
            </div>

            <AppTimeline>
              <AppTimelineItem
                v-for="(event, eventIndex) in item.events"
                :key="eventIndex"
                :title="event.message"
                :time="$helpers.formatDateTime(event.created_at)"
              />
            </AppTimeline>
          </b-col>

          <b-col cols="12" lg="6" class="px-4 mb-2">
            <div class="mb-2">
              <h3>
                <FeatherIcon icon="BoxIcon" size="30" />
                <span class="ml-2">Order Summary</span>
              </h3>
            </div>

            <b-row>
              <b-col cols="12" lg="6">
                <AppTimeline>
                  <AppTimelineItem title="Order Overview" icon="ArchiveIcon">
                    <div class="mb-2">
                      <h4>Order Overview</h4>
                    </div>

                    <div class="mb-2">
                      <h5>Order No.</h5>
                      <span>
                        {{ item.order_no ? item.order_no : '-' }}
                      </span>
                    </div>

                    <div class="mb-2">
                      <h5>Status</h5>
                      <b-badge variant="primary" class="badge-padding">
                        {{ item.status }}
                      </b-badge>
                    </div>

                    <div class="mb-2">
                      <h5>Shipment</h5>
                      <b-badge variant="danger" class="badge-padding">
                        {{ item.order_type }}
                      </b-badge>
                    </div>

                    <div class="mb-2">
                      <h5>Total Spent</h5>
                      <span>
                        {{ $helpers.formatTotal(item.amount) }}
                      </span>
                    </div>
                  </AppTimelineItem>

                  <AppTimelineItem
                    v-if="item.order_type.includes('distributor')"
                    title="Product Details"
                    icon="BoxIcon"
                  >
                    <div class="mb-2">
                      <h4>Product Details</h4>
                    </div>

                    <div class="mb-2">
                      <h5>Product Category</h5>
                      <span>
                        {{ item.product_category }}
                      </span>
                    </div>

                    <div class="mb-2">
                      <h5>Packaging</h5>
                      <span>
                        {{ item.packaging }}
                      </span>
                    </div>

                    <div class="mb-2">
                      <h5>Quantity</h5>
                      <span>
                        {{ item.quantity }}
                      </span>
                    </div>

                    <div class="mb-2">
                      <h5>Size</h5>
                      <span>
                        {{ item.item_size }}
                      </span>
                    </div>

                    <div class="mb-2">
                      <h5>Weight</h5>
                      <span>
                        {{ item.item_weight }}
                      </span>
                    </div>

                    <div class="mb-2">
                      <h5>Temperature</h5>
                      <span>
                        {{ item.temperature }}
                      </span>
                    </div>

                    <div class="mb-2">
                      <h5>Remarks</h5>
                      <span>
                        {{ item.remarks }}
                      </span>
                    </div>
                  </AppTimelineItem>
                </AppTimeline>
              </b-col>

              <b-col cols="12" lg="6">
                <AppTimeline>
                  <AppTimelineItem title="Address Details" icon="MapIcon">
                    <div class="mb-2">
                      <h4>Address Details</h4>
                    </div>

                    <div class="mb-2">
                      <h5>Sender Name</h5>
                      <span>
                        {{
                          `${item.sender.first_name} ${
                            item.sender.last_name ? item.sender.last_name : ''
                          }`
                        }}
                      </span>
                    </div>

                    <div class="mb-2">
                      <h5>Sender Address</h5>
                      <span>
                        {{ item.sender.complete_address }}
                      </span>
                    </div>

                    <hr />

                    <div class="mb-2">
                      <h5>Recipient Name</h5>
                      <span>
                        {{
                          `${item.recipient.first_name} ${
                            item.recipient.last_name
                              ? item.recipient.last_name
                              : ''
                          }`
                        }}
                      </span>
                    </div>

                    <div class="mb-2">
                      <h5>Recipient Address</h5>
                      <span>
                        {{ item.recipient.complete_address }}
                      </span>
                    </div>
                  </AppTimelineItem>
                </AppTimeline>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </AppCollapseItem>
    </AppCollapse>
  </b-overlay>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import { requestError } from '@/common/SwalOptions';

export default {
  name: 'RoutePlanSummary',
  components: {
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    routeId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isLoading: false,
      summary: [],
    };
  },
  created() {
    this.getRouteSummary();
  },
  methods: {
    async getRouteSummary() {
      this.isLoading = true;
      const response = await this.$http.get(
        `route_summary?route_id=${this.routeId}`
      );
      if (!response.status === 200) {
        this.$swal(requestError);
        this.isLoading = false;
        return;
      }

      this.summary = response.data.data;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
